<template>
  <div>
    <!--begin::details View-->
    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0 align-items-start flex-column">
          <h3 class="card-title align-items-start flex-column">
						 <span class="fw-bolder text-dark">Component Lot Record</span>
						 <span class="text-muted mt-3 font-weight-bold fs-sm">{{ store.getters.myLotDetails ? store.getters.myLotDetails.code+' - '+store.getters.myLotDetails.name : '' }}</span>
					</h3>
        </div>
        <!--end::Card title-->
      </div>

      <!--begin::Card body-->
      <div class="card-body p-9">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="details-tab" data-bs-toggle="tab" data-bs-target="#details" type="button" role="tab" aria-controls="details" aria-selected="true">Details</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="activity-tab" data-bs-toggle="tab" data-bs-target="#activity" type="button" role="tab" aria-controls="activity" aria-selected="false">Activity</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="prices-tab" data-bs-toggle="tab" data-bs-target="#prices" type="button" role="tab" aria-controls="prices" aria-selected="false">Delivery Details</button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details-tab">
            <ComponentLotDetails :id="route.params.id" />
          </div>
          <div class="tab-pane fade" id="activity" role="tabpanel" aria-labelledby="activity-tab">
            <ComponentLotActivities :id="route.params.id" />
          </div>
          <div class="tab-pane fade" id="prices" role="tabpanel" aria-labelledby="prices-tab">
            <ComponentLotDeliveryDetails :id="route.params.id" />
          </div>
        </div>

      </div>

    </div>

  </div>
</template>
<script lang="ts">
import { onMounted } from 'vue';
import ComponentLotDetails from '@/views/lots/components/ComponentLotDetails.vue'
import ComponentLotDeliveryDetails from '@/views/lots/components/ComponentLotDeliveryDetails.vue'
import ComponentLotActivities from '@/views/lots/components/ComponentLotActivities.vue'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions as StoreActions } from "@/store/enums/StoreEnums.ts";
import { Actions } from "@/store/enums/LotEnums.ts";
export default {
  name: 'ComponentLotEdit',
  components : {
    ComponentLotDetails,
    ComponentLotDeliveryDetails,
    ComponentLotActivities
  },
  setup(){
    const route = useRoute();
    const store = useStore();

    store.dispatch(StoreActions.ADD_BODY_CLASSNAME,'page-loading')

    onMounted(() => {
      setCurrentPageBreadcrumbs("Lots",[
        {title : "Lots",to : "/lots/list"},
        {title : "Component Lot Record",to : "/lots/component/edit/"+route.params.id}
      ]);

      store.dispatch(Actions.GET_LOT_DETAILS,{id : route.params.id}).then(() => {

        store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME,'page-loading')

      });

    });

    return {
      route,
      store
    }
  }
}
</script>
