<template>
  <div class="pt-10">
    <div class="row mb-5">
      <div class="col-12 mb-5" v-show="success">
        <div class="row mb-0">
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              Successfully updated LOT delivery details!
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-4">

        <div class="form-group mb-3">
          <label class="col-form-label required fw-bold fs-6 pt-0">Supplier</label>
          <input type="text" class="form-control" :value="store.getters.myLotDeliveryDetails.supplier" :disabled="true">
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label required fw-bold fs-6">Delivery Date</label>
          <input type="date" class="form-control" :class="{'is-invalid' : errors.value && errors.value.deliveryDate}" v-model="store.getters.myLotDeliveryDetails.deliveryDate">
          <span class="text-danger" v-if="errors.value && errors.value.deliveryDate">{{errors.value && errors.value.deliveryDate[0]}}</span>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label required fw-bold fs-6">Manufacture Date</label>
          <input type="date" class="form-control" :class="{'is-invalid' : errors.value && errors.value.manufactureDate}" v-model="store.getters.myLotDeliveryDetails.manufactureDate">
          <span class="text-danger" v-if="errors.value && errors.value.manufactureDate">{{errors.value && errors.value.manufactureDate[0]}}</span>
        </div>

        <div class="form-group mb-3">
          <label class="col-form-label required fw-bold fs-6">Units</label>
          <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.units}" v-model="store.getters.myLotDeliveryDetails.units">
          <span class="text-danger" v-if="errors.value && errors.value.units">{{errors.value && errors.value.units[0]}}</span>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="text-end m-0 pt-5 border-top">
          <button
            type="submit"
            ref="submitButton"
            class="btn btn-lg btn-primary fw-bolder mt-2"
            @click="submitForm"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { reactive, ref, onMounted, computed } from 'vue'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/LotEnums.ts";
// import { useRouter } from "vue-router";

export default {
  name : 'ComponentLotDeliveryDetails',
  props : ["id"],
  setup(props){
    const store = useStore();
    // const router = useRouter();

    const success = ref(false);

    onMounted(() => {
      store.dispatch(Actions.GET_LOT_DELIVERY_DETAILS,{id : props.id})
    })

    const submitButton = ref<HTMLElement | null>(null);

    const errors = reactive({
      value : {}
    });

    const submitForm = () => {
      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.UPDATE_LOT_DELIVERY_DETAILS,store.getters.myLotDeliveryDetails).then(() => {

        errors.value = [];
        success.value = true;
        window.scrollTo(0,0);
        submitButton.value?.removeAttribute("data-kt-indicator");
        // setTimeout(() => {
        //   router.push('/products/list')
        // },1000)

      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }

    return {
      success,
      store,
      submitButton,
      submitForm,
      errors
    }
  }
}
</script>
