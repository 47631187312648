
import { reactive, ref, onMounted, computed } from 'vue'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/LotEnums.ts";
// import { useRouter } from "vue-router";

export default {
  name : 'ComponentLotDetails',
  props : ["id"],
  setup(props){
    const store = useStore();
    // const router = useRouter();

    const success = ref(false);



    const submitButton = ref<HTMLElement | null>(null);

    const errors = reactive({
      value : {}
    });

    const submitForm = () => {
      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.UPDATE_LOT_DETAILS,store.getters.myLotDetails).then(() => {

        errors.value = [];
        success.value = true;
        window.scrollTo(0,0);
        submitButton.value?.removeAttribute("data-kt-indicator");
        // setTimeout(() => {
        //   router.push('/products/list')
        // },1000)

      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }

    return {
      success,
      store,
      submitButton,
      submitForm,
      errors
    }
  }
}
