
import { onMounted, computed } from 'vue'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/ActivitiesEnums.ts";
import { useRouter } from "vue-router";
import Table from '@/components/custom/Table.vue';
import moment from 'moment'
export default {
  name : 'ComponentLotActivities',
  components : {
    Table
  },
  props : ["id"],
  setup(props){

    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      store.dispatch(Actions.GET_LOT_COMPONENT_TOTALS,{id : props.id});
    });

    const actions = computed(() => {
      return Actions;
    })

    const columns = [
      {name : 'activityType', title : 'Activity Type', sortable : true, sort : null, searchable : true},
      {name : 'activityDate', title : 'Activity Date', sortable : true, sort : 'asc', searchable : true},
      {name : 'quantity', title : 'Quantity',sortable : false, sort : null, searchable : false},
    ];

    const generateActivityType = (activityType) => {

      let str = '';

      switch(activityType){

        case 'orderedFromSupplier':

        str = "<i class='fs-1-5 la la-cart-arrow-down me-3'></i> Ordered From Supplier";

        break;

        case 'receivedFromSupplier':

        str = "<i class='fs-1-5 la la-cart-plus me-3'></i> Received From Supplier";

        break;

        case 'used':

        str = "<i class='fs-1-5 la la-boxes me-3'></i> Used in Assemblies";

        break;

        case 'adjusted':

        str = "<i class='fs-1-5 la la-edit me-3'></i> Adjusted";

        break;

      }

      return str;

    }

    return {
      columns,
      store,
      router,
      Table,
      props,
      actions,
      generateActivityType,
      moment
    }
  }
}
