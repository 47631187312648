
import { onMounted } from 'vue';
import ComponentLotDetails from '@/views/lots/components/ComponentLotDetails.vue'
import ComponentLotDeliveryDetails from '@/views/lots/components/ComponentLotDeliveryDetails.vue'
import ComponentLotActivities from '@/views/lots/components/ComponentLotActivities.vue'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions as StoreActions } from "@/store/enums/StoreEnums.ts";
import { Actions } from "@/store/enums/LotEnums.ts";
export default {
  name: 'ComponentLotEdit',
  components : {
    ComponentLotDetails,
    ComponentLotDeliveryDetails,
    ComponentLotActivities
  },
  setup(){
    const route = useRoute();
    const store = useStore();

    store.dispatch(StoreActions.ADD_BODY_CLASSNAME,'page-loading')

    onMounted(() => {
      setCurrentPageBreadcrumbs("Lots",[
        {title : "Lots",to : "/lots/list"},
        {title : "Component Lot Record",to : "/lots/component/edit/"+route.params.id}
      ]);

      store.dispatch(Actions.GET_LOT_DETAILS,{id : route.params.id}).then(() => {

        store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME,'page-loading')

      });

    });

    return {
      route,
      store
    }
  }
}
